<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="page-container-card primary--border" outlined>
                    <v-card-title class="title">
                        Sliders
                        <v-spacer></v-spacer>
                        <add-button icon="crop_free" v-if="form.items.data.length>=1" @action="slider_preview = true"
                                    permission="slider-read">
                            Preview
                        </add-button>

                        <add-button v-if="form.items.data.length<5"
                                    @action="form.dialog = true,$refs.form ? $refs.form.reset(): ''"
                                    permission="slider-create">
                            New Slide
                        </add-button>
                    </v-card-title>
                    <v-alert :value="true" color="warning" icon="priority_high"
                             v-if="form.items.data.length>=5">
                        Looks like you have reached your limit. Only <strong>{{form.items.data.length}} Slide
                        Images</strong> are allowed.
                    </v-alert>
                    <div v-if="form.items.data.length>0" class="data-represent">
                        <div>
                            <small><strong>{{form.items.data.length}} </strong> out of <strong>5</strong>
                                images
                            </small>
                        </div>
                    </div>
                    <v-layout style="padding: 0 20px;" wrap>
                        <v-flex v-for="(item,key) in form.items.data" :key="key" xs3>
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat tile class="d-flex">
                                    <v-img :src="item.image" :lazy-src="item.image" aspect-ratio="1"
                                           class="grey lighten-2">
                                        <v-expand-transition>
                                            <div v-if="hover"
                                                 class="d-flex transition-fast-in-fast-out blue darken-2 v-card--reveal display-3 white--text"
                                                 style="height: 100%;">
                                                <div class="album_style_mis">
                                                    <h3>{{item.title.toUpperCase()}}</h3>
                                                    <p>{{item.description}}</p>
                                                </div>
                                            </div>
                                        </v-expand-transition>
                                        <template v-slot:placeholder>
                                            <v-layout fill-height align-center justify-center ma-0>
                                                <v-progress-circular indeterminate
                                                                     color="grey lighten-5"></v-progress-circular>
                                            </v-layout>
                                        </template>
                                    </v-img>

                                    <div style="position: absolute;z-index: 99;bottom: 10px;right: 10px;">
                                        <edit-button permission="slider-update" @agree="form.edit(item)"
                                                     :outlined="false"/>
                                        <delete-button permission="slider-delete" @agree="form.delete(item.id)"
                                                       :outlined="false"/>
                                    </div>
                                </v-card>
                            </v-hover>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Slider Details</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                v-model="valid"
                                :lazy-validation="lazy">
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12 class="mb-0">
                                        <v-text-field outlined dense label="Slider Title*" autocomplete="off" required
                                                      class="pa-0"
                                                      v-model="form.title" name="name"
                                                      :error-messages="form.errors.get('title')"
                                                      :rules="[v => !!v || 'Slider Title Field is required']"/>
                                    </v-flex>
                                    <v-flex xs12 class="ma-0 pa-0">
                                        <v-textarea outlined dense :error-messages="form.errors.get('description')"
                                                    v-model="form.description" name="description" label="Description*"
                                                    hint="Short and sweet notice is always eye catching."
                                                    :rules="[v => !!v || 'Description Title Field is required']"/>
                                    </v-flex>
                                    <v-scroll-y-reverse-transition>
                                        <div v-if="imagePicker.image || form.data().image"  style="cursor:pointer;height: 266px;width: 100%;background-color: #ccc;border: 1px dashed #999">
                                            <v-flex xs12 text-center class="pa-0" v-if="imagePicker.image"
                                                    style="position: relative;">
                                                <v-img
                                                        :src="imagePicker.image"
                                                        gradient="to top right, rgba(100,115,201,.11), rgba(25,32,72,.4)"
                                                ></v-img>
                                            </v-flex>
                                            <v-flex xs12 text-center class="pa-0"
                                                    style="position: relative;" v-else-if="form.data().image">
                                                <!--<v-avatar :size="112" color="grey lighten-4">-->
                                                    <!--<img :src="form.data().image" alt="avatar">-->
                                                <!--</v-avatar>-->
                                                <v-img
                                                        :src="form.data().image"
                                                        gradient="to top right, rgba(100,115,201,.11), rgba(25,32,72,.4)"
                                                ></v-img>
                                            </v-flex>
                                        </div>

                                    </v-scroll-y-reverse-transition>
                                    <v-flex xs12 text-center class="">
                                        <image-picker label="Select Slider Image" ref="picker" :ratio="16/9"
                                                      @result="onPickerResult"></image-picker>
                                    </v-flex>
                                    <v-flex xs12>
                                        <small>*indicates required field</small>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog @keydown.esc="slider_preview = false" v-model="slider_preview" persistent max-width="1000px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Slider Preview</span>
                        <v-spacer></v-spacer>
                        <v-icon @click="slider_preview = false" style="color: white">close</v-icon>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-carousel>
                            <v-carousel-item v-for="(item,i) in form.items.data" :key="i"
                                             :src="item.image"></v-carousel-item>
                        </v-carousel>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    export default {
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                title: '',
                description: '',
                image: ''
            }, '/api/slider'),
            search: null,
            pagination: {},
            imagePicker: {
                name: null,
                image: null,
                src: null,
                cropped: {}
            },
            slider_preview: false
        }),

        computed: {
            ...mapState(['batch'])
        },

        mounted() {
            this.get();
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] === 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&');
            },
            validate() {
                if (this.$refs.form.validate()) {
                    this.store()
                }
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            store() {
                if (this.imagePicker && Object.keys(this.imagePicker.cropped).length > 0) {
                    this.form.image = this.imagePicker.cropped;
                } else {
                    delete this.form.image;
                }
                if (this.form.data.length < 5)
                    this.form.store().then(() => {
                        this.get();
                    });
            },

            onPickerResult(data) {
                this.imagePicker = data;
                this.$refs.picker.reset();
            },

        }
    }
</script>
<style lang="scss" scoped>
    .v-card--reveal {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .5;
        position: absolute;
        width: 100%;
    }

    .album_style_mis {
        padding: 0 20px;
        width: 50% !important;

        h3 {
            font-size: 17px !important;
            border-bottom: 1px solid white;
        }

        p {
            font-size: 15px;
        }
    }

    .container.grid-list-md .layout .flex {
        margin-bottom: 40px;
    }

    .v-btn--bottom.v-btn--absolute.v-btn--small.edit {
        right: 60px;
    }
</style>